import { useMemo } from 'react';

import { MenuItem, Select } from '@mui/material';
import { DE, GB } from 'country-flag-icons/string/3x2';
import { t } from 'i18next';

import { CountryCode, LanguageCode } from '@components/header/enums';
import { languageToLocale, localeToLanguage } from '@tools/enums/locales';

import i18n from '@src/i18n';
import {
    replaceHyphensWithUnderscores,
    replaceUnderscoresWithHyphens,
} from '@tools/utils';

import styles from '@components/select-language/index.module.scss';

const SelectLanguage = () => {
    const languageMenuItems = useMemo(
        () => [
            {
                flag: GB,
                languageCode: LanguageCode.ENGLISH,
                countryCode: CountryCode.ENGLISH,
                language: t('English'),
            },
            {
                flag: DE,
                languageCode: LanguageCode.DEUTSCH,
                countryCode: CountryCode.DEUTSCH,
                language: t('Deutsch'),
            },
        ],
        [],
    );

    return (
        <Select
            value={
                localeToLanguage[
                    replaceHyphensWithUnderscores(i18n.language ?? 'en')
                ]
            }
            onChange={(e) =>
                i18n.changeLanguage(
                    replaceUnderscoresWithHyphens(
                        languageToLocale[e.target.value],
                    ),
                )
            }
            className={styles.select}
            MenuProps={{
                className: styles.dropdown,
            }}
        >
            {languageMenuItems.map(({ languageCode, language, flag }) => (
                <MenuItem key={languageCode} value={languageCode}>
                    <div className={styles.language}>
                        <div
                            className={styles.flag}
                            dangerouslySetInnerHTML={{
                                __html: flag,
                            }}
                        />

                        <strong className={styles.language__name}>
                            {language}
                        </strong>
                    </div>
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectLanguage;

import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';

import { AlertColor, SnackbarProps } from '@mui/material';

type ToastContext = {
    toastList: ToastParams[] | null;
    showToast: (args: ToastParams) => void;
    removeToast: (key: string | number) => void;
};

const defaultMaxAmount = 5;

type ToastNotificationsProviderProps = {
    maxAmount?: number;
};

export type ToastParams = {
    message: string;
    toastType?: AlertColor;
    key?: string | number;
    options?: SnackbarProps;
    onCloseCallback?: () => void;
};

const ToastNotificationContext = createContext<ToastContext | undefined>(
    undefined,
);

export const ToastNotificationsProvider = ({
    maxAmount,
    children,
}: PropsWithChildren<ToastNotificationsProviderProps>) => {
    const [toastList, setToastList] = useState<ToastParams[] | null>(null);

    const showToast = useCallback(
        (params: ToastParams) => {
            const type = params?.toastType ?? 'info'; // let's have 'info'by default
            const key = params?.key ?? new Date().getMilliseconds();

            const toastPayload = {
                ...params,
                type,
                key,
            };

            setToastList((prev: ToastParams[]) => {
                if (prev === null) {
                    return [toastPayload];
                }

                // if we having a key added as a param, makes no sense to show duplicate message
                if (prev?.find((t) => t.key === toastPayload.key)) {
                    return prev;
                }

                const max = maxAmount ?? defaultMaxAmount;
                const prevToasts = prev?.length < max ? prev : prev?.slice(1);

                return [...prevToasts, toastPayload];
            });
        },
        [setToastList, maxAmount],
    );

    const removeToast = useCallback(
        (key: ToastParams['key']) => {
            setToastList((prev: ToastParams[]) =>
                prev?.length
                    ? prev?.filter((toast) => toast.key !== key)
                    : null,
            );
        },
        [setToastList],
    );

    const value = useMemo(
        () => ({ toastList, showToast, removeToast }),
        [removeToast, showToast, toastList],
    );

    return (
        <ToastNotificationContext.Provider value={value}>
            {children}
        </ToastNotificationContext.Provider>
    );
};

const useToastList = () => {
    const context = useContext(ToastNotificationContext);
    if (context === undefined) {
        throw new Error(
            '`useToast` must be used within `ToastNotificationsProvider`. Wrap your component tree with <ToastNotificationsProvider>.',
        );
    }

    return context;
};

export default useToastList;

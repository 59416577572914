export type IsoCountries =
    | 'en_GB'
    | 'de_DE'
    | 'sv_SE'
    | 'it_IT'
    | 'pl_PL'
    | 'hu_HU'
    | 'cs_CZ'
    | 'nl_NL'
    | 'hr_HR';

export interface Country {
    readonly id: string;
    readonly iso: IsoCountries;
    readonly name: string;
}

export const languages: Record<string, string> = {
    en: 'English',
    de: 'Deutsch',
    it: 'Italiano',
    sv: 'Svenska',
    hu: 'Magyar',
    pl: 'Polski',
    cs: 'Čeština',
    nl: 'Dutch',
    hr: 'Hrvatski',
};

export const countries: Record<string, string> = {
    en: 'United Kingdom',
    de: 'Germany',
    it: 'Italy',
    sv: 'Sweden',
    hu: 'Hungary',
    pl: 'Poland',
    cs: 'Czech Republic',
    nl: 'Netherlands',
    hr: 'Croatia',
};

export const languagesAbbreviation: Record<string, string> = {
    en: 'EN',
    de: 'DE',
    it: 'IT',
    sv: 'SV',
    hu: 'HU',
    pl: 'PL',
    cs: 'CS',
    nl: 'NL',
    hr: 'HR',
};

export const locales: Record<string, string> = {
    en: 'UK',
    de: 'DE',
    it: 'IT',
    sv: 'SE',
    hu: 'HU',
    pl: 'PL',
    cs: 'CZ',
    nl: 'NL',
    hr: 'HR',
};

export const languageToLocale: Record<string, IsoCountries> = {
    en: 'en_GB',
    de: 'de_DE',
    it: 'it_IT',
    sv: 'sv_SE',
    hu: 'hu_HU',
    pl: 'pl_PL',
    cs: 'cs_CZ',
    nl: 'nl_NL',
    hr: 'hr_HR',
};

export const localeToLanguage: Record<string, string> = Object.entries(
    languageToLocale,
).reduce(
    (acc, [key, value]) => {
        acc[value] = key;
        return acc;
    },
    {} as Record<string, string>,
);

export const affiliateCountries = ['GB', 'DE', 'IT', 'SE', 'HU', 'PL'];

export const appLocales: Country[] = Object.keys(languages).map(
    (lang: string) => ({
        id: locales[lang],
        iso: languageToLocale[lang],
        name: languages[lang],
    }),
);

export const appCountries: Country[] = Object.keys(languages).map(
    (lang: string) => ({
        id: locales[lang],
        iso: languageToLocale[lang],
        name: countries[lang],
    }),
);

export const appLanguages = Object.keys(languages).map((lang: string) => ({
    id: languagesAbbreviation[lang],
    name: languages[lang],
}));

// remove after FEH-19265 rollout
export function supportedCountries() {
    return appCountries;
}

export const FALLBACK_LANGUAGE = languageToLocale['en'];

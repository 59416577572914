import {
    ReactNode,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExtensionIcon from '@mui/icons-material/Extension';
import FitbitIcon from '@mui/icons-material/Fitbit';
import GroupIcon from '@mui/icons-material/Group';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Stream from '@mui/icons-material/Stream';
import {
    Chip,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
} from '@mui/material';

import { Routes } from '@api/tools';

import { useCrmCustomersGetCustomersQuery } from '@queries/customers';
import { useAccountGetAccountUsersQuery } from '@queries/users';
import { useGetVendorsHealth } from '@queries/vendors';
import { Breakpoint } from '@tools/enums/breakpoints';
import { MenuClasses } from '@tools/enums/menu';
import { checkForScopes } from '@tools/utils/scopes';

import SelectLanguage from '@components/select-language';
import useAuthContext from '@context/auth-provider';
import {
    cmsReadScope,
    featuresReadScope,
    settingsReadScope,
    unallocatedInstallationsReadScope,
} from '@tools/constants';
import { Scope } from '@tools/enums';
import {
    getActiveItemFromPathname,
    getActiveItemFromSearch,
    handleResizeClassRemoval,
    toggleElementClass,
} from '@tools/utils';

import styles from '@components/menu/index.module.scss';

type MenuItem = {
    id: string;
    title: string;
    icon: ReactNode;
    link: string;
    label?: ReactNode;
    isHidden?: boolean;
};

type MenuItemGroup = {
    heading?: string;
    items: MenuItem[];
    isHidden?: boolean;
};

export const Menu = () => {
    const { t } = useTranslation();
    const { pathname, search } = useLocation();
    const { isLoggedIn } = useAuthContext();
    const { signOut } = useAuthContext();

    const [activeItem, setActiveItem] = useState(
        () => localStorage.getItem('activeItem') ?? null,
    );

    const { data: vendors } = useGetVendorsHealth(isLoggedIn);

    const { data: customers, isLoading: isLoadingCustomers } =
        useCrmCustomersGetCustomersQuery({
            page: 0,
            limit: 1,
            order: undefined,
            filter: {},
            searchValue: '',
            enabled: isLoggedIn,
        });

    const hasPolicy = useMemo(
        () => checkForScopes([Scope.ACCOUNT_USERS_READ]),
        [],
    );

    const { data: members, isLoading: isLoadingMembers } =
        useAccountGetAccountUsersQuery(hasPolicy, 0, 0, undefined, isLoggedIn);

    const expiredVendorsCount = useMemo(() => {
        if (!vendors) {
            return 0;
        }

        return vendors.filter((vendor) => vendor.health !== 'valid').length;
    }, [vendors]);

    const unallocatedInstallationsNumber = useMemo(
        () =>
            // TODO: get the number of unallocated installations from BE
            0,
        [],
    );

    const menuItemGroups: MenuItemGroup[] = useMemo(
        () => [
            {
                heading: t('common.applicationName'),
                items: [
                    {
                        id: 'dashboard-menu',
                        title: t('menu.dashboard'),
                        icon: <DashboardIcon fontSize="small" />,
                        link: Routes.DASHBOARD,
                    },
                    {
                        id: 'business-report-menu',
                        title: t('menu.businessReport'),
                        icon: <ExtensionIcon fontSize="small" />,
                        link: Routes.BUSINESS_REPORTS,
                        isHidden: !checkForScopes([
                            Scope.BUSINESS_REPORTS_READ,
                        ]),
                    },
                    {
                        id: 'members-menu',
                        title: t('menu.members'),
                        icon: <GroupIcon fontSize="small" />,
                        link: Routes.ACCOUNT_MEMBERS,
                        isHidden: !hasPolicy,
                        label: !isLoadingMembers ? (
                            <Chip
                                label={members?.count}
                                size="small"
                                className={`${styles.item__chip} ${styles['item__chip--dark']}`}
                            />
                        ) : undefined,
                    },
                    {
                        id: 'customers-menu',
                        title: t('menu.customers'),
                        icon: <ManageAccountsIcon fontSize="small" />,
                        link: Routes.CUSTOMERS,
                        isHidden: !checkForScopes([Scope.CUSTOMERS_READ]),
                        label: !isLoadingCustomers ? (
                            <Chip
                                label={customers?.count}
                                size="small"
                                className={`${styles.item__chip} ${styles['item__chip--dark']}`}
                            />
                        ) : undefined,
                    },
                    {
                        id: 'unallocated-installations-menu',
                        title: t(
                            'menu.unallocatedInstallations',
                            'Unallocated Installations',
                        ),
                        icon: <Stream fontSize="small" />,
                        link: Routes.UNALLOCATED_INSTALLATIONS,
                        isHidden: !checkForScopes(
                            unallocatedInstallationsReadScope,
                        ),
                        label: unallocatedInstallationsNumber ? (
                            <Chip
                                label={unallocatedInstallationsNumber}
                                size="small"
                                className={`${styles.item__chip} ${styles['item__chip--dark']}`}
                            />
                        ) : undefined,
                    },
                    {
                        id: 'integration-settings-menu',
                        title: t('menu.integrationSettings'),
                        icon: <SettingsIcon fontSize="small" />,
                        link: Routes.INTEGRATION_SETTINGS,
                        label:
                            checkForScopes([Scope.SYSTEM_SETTINGS_READ]) &&
                            expiredVendorsCount ? (
                                <Chip
                                    label={expiredVendorsCount}
                                    size="small"
                                    color="primary"
                                    className={styles.item__chip}
                                />
                            ) : undefined,
                        isHidden: !checkForScopes(settingsReadScope),
                    },
                    {
                        id: 'feature-groups-menu',
                        title: t('menu.featureGroups'),
                        icon: <FitbitIcon fontSize="small" />,
                        link: Routes.FEATURE_GROUPS,
                        isHidden: !checkForScopes(featuresReadScope),
                    },
                ],
            },
            {
                heading: t('common.activity'),
                isHidden: !checkForScopes([Scope.ACTIVITIES_READ]),
                items: [
                    {
                        id: 'account-activityLog-menu',
                        title: t('menu.accountActivityLog'),
                        icon: <HistoryIcon fontSize="small" />,
                        link: Routes.ACCOUNT_ACTIVITY_LOG,
                    },
                    {
                        id: 'my-activity-log-menu',
                        title: t('menu.myActivityLog'),
                        icon: <HistoryIcon fontSize="small" />,
                        link: Routes.PROFILE_ACTIVITY_LOG,
                    },
                ],
            },
            {
                heading: t('common.contentManagementSystem'),
                isHidden: !checkForScopes(cmsReadScope),
                items: [
                    {
                        id: 'content-menu',
                        title: t('menu.content'),
                        icon: <ArticleIcon fontSize="small" />,
                        link: Routes.CMS_CONTENT,
                        isHidden: !checkForScopes([Scope.CMS_CONTENT_READ]),
                    },
                    {
                        id: 'schemas-menu',
                        title: t('menu.schemas'),
                        icon: <SettingsApplicationsIcon fontSize="small" />,
                        link: Routes.CMS_SCHEMAS,
                        isHidden: !checkForScopes([Scope.CMS_SETTINGS_READ]),
                    },
                    {
                        id: 'instructions-menu',
                        title: t('menu.instructions'),
                        icon: <HelpCenterIcon fontSize="small" />,
                        link: `${Routes.CMS_INSTRUCTIONS}`,
                        isHidden: !checkForScopes([Scope.CMS_CONTENT_READ]),
                    },
                ],
            },

            {
                heading: t(
                    'common.contentManagementSystemV2',
                    'Content management system V2',
                ),
                isHidden: !checkForScopes([
                    ...cmsReadScope,
                    Scope.CMS_CLOUD_NATIVE,
                ]),
                items: [
                    {
                        id: 'content-menu-v2',
                        title: t('menu.content'),
                        icon: <ArticleIcon fontSize="small" />,
                        link: Routes.CMS_V2_CONTENT,
                        isHidden: !checkForScopes(
                            [Scope.CMS_CONTENT_READ, Scope.CMS_CLOUD_NATIVE],
                            true,
                        ),
                    },
                    {
                        id: 'schemas-menu-v2',
                        title: t('menu.schemas'),
                        icon: <SettingsApplicationsIcon fontSize="small" />,
                        link: Routes.CMS_V2_SCHEMAS,
                        isHidden: !checkForScopes(
                            [Scope.CMS_SETTINGS_READ, Scope.CMS_CLOUD_NATIVE],
                            true,
                        ),
                    },
                    {
                        id: 'instructions-menu-v2',
                        title: t('menu.instructions'),
                        icon: <HelpCenterIcon fontSize="small" />,
                        link: `${Routes.CMS_INSTRUCTIONS}`,
                        isHidden: !checkForScopes([Scope.CMS_CONTENT_READ]),
                    },
                ],
            },
        ],
        [
            t,
            hasPolicy,
            isLoadingMembers,
            members?.count,
            isLoadingCustomers,
            customers?.count,
            expiredVendorsCount,
            unallocatedInstallationsNumber,
        ],
    );

    useEffect(() => {
        /*
         ### Active Menu Item Detection:
         * - **Functions:**
         *   - `getActiveItemFromSearch`: Extracts the `id` parameter from the search query string
         *     and maps it to a menu item ID.
         *   - `getActiveItemFromPathname`: Matches the link (first three segments) of the
         *     pathname with the item's `link` to determine the active item.
         *
         * - **Root Path Matching:**
         *   - The `pathname.split('/').slice(0, 3).join('/') === item.link` logic ensures the active
         *     menu item is correctly identified by abstracting away additional path segments
         *     introduced by redirects. This aligns the pathname to the "link" defined in
         *     the menu mapping, ensuring consistency in active state selection.
         *
         * ### Why this approach was necessary:
         * - Some pages have internal redirects, which can cause the active menu item to lose its
         *   reference. To handle this:
         *   - Pages without redirects rely on `id` from the search query.
         *   - Pages with redirects use the pathname to map to the correct menu item.
         * - This dual approach ensures that the active menu item is always accurate, even with
         *   redirects or complex navigation structures.
        */

        const activeItem =
            (search && getActiveItemFromSearch(menuItemGroups, search)) ||
            (pathname && getActiveItemFromPathname(menuItemGroups, pathname));

        setActiveItem(activeItem || null);
    }, [search, pathname, menuItemGroups]);

    useLayoutEffect(() => {
        const onResizeHandler = () => {
            const targetElement = document.body;
            handleResizeClassRemoval(
                targetElement,
                Breakpoint.MD,
                MenuClasses.EXPANDED,
            );
        };

        window.addEventListener('resize', onResizeHandler);
        onResizeHandler();

        return () => {
            window.removeEventListener('resize', onResizeHandler);
        };
    }, []);

    return (
        <Paper className={`main-menu ${styles.menu}`}>
            <MenuList>
                {menuItemGroups
                    .filter((menuItem) => !menuItem.isHidden)
                    .map((group) => [
                        group.heading && (
                            <MenuItem
                                disabled
                                divider
                                className={`${styles.heading} ${styles.heading__title}`}
                            >
                                <ListItemText>{group.heading}</ListItemText>
                            </MenuItem>
                        ),
                        group.items.map((item) => {
                            const itemId = `${item.id}`;
                            const handleItemClick = () => {
                                const storedActiveItem =
                                    localStorage.getItem('activeItem');
                                if (storedActiveItem) {
                                    setActiveItem(storedActiveItem); // Restore active item from localStorage
                                } // Update active item

                                if (window.innerWidth <= Breakpoint.MD) {
                                    toggleElementClass(
                                        document.body,
                                        MenuClasses.EXPANDED,
                                    );
                                }
                            };

                            const isActive = activeItem === itemId; // Match the stored active item with the item's link

                            return item.isHidden ? null : (
                                <li
                                    key={item.title}
                                    className={styles.item}
                                    data-testid={item.id}
                                >
                                    <MenuItem
                                        to={`${item.link}?id=${item.id}`}
                                        divider
                                        selected={isActive}
                                        component={NavLink}
                                        className={
                                            isActive ? styles.active : undefined
                                        }
                                        onClick={handleItemClick}
                                    >
                                        <ListItemIcon
                                            className={styles.menu__icon}
                                        >
                                            {item.icon}
                                        </ListItemIcon>

                                        <ListItemText
                                            className={styles.menu__text}
                                        >
                                            {item.title}
                                        </ListItemText>

                                        {item.label && (
                                            <span>{item.label}</span>
                                        )}
                                    </MenuItem>
                                </li>
                            );
                        }),
                    ])}

                <li data-testid="user-actions" className={styles.user__actions}>
                    <MenuList role="action-user">
                        <MenuItem
                            disabled
                            divider
                            className={`${styles.heading} ${styles.heading__title}`}
                        >
                            <ListItemText>
                                {t('common.userActions', 'User Actions')}
                            </ListItemText>
                        </MenuItem>

                        <MenuItem
                            divider
                            className={styles.item}
                            onClick={signOut}
                        >
                            <ListItemIcon className={styles.menu__icon}>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText className={styles.menu__text}>
                                {t('common.logout')}
                            </ListItemText>
                        </MenuItem>

                        <li>
                            <SelectLanguage />
                        </li>
                    </MenuList>
                </li>
            </MenuList>

            <div className={styles.text}>
                <span>{t('common.applicationName')}</span>
            </div>
        </Paper>
    );
};

export default Menu;

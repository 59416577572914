import { Info } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        turquoise: Palette['primary'];
    }

    interface PaletteOptions {
        turquoise?: PaletteOptions['primary'];
    }
}

let theme = createTheme({
    palette: {
        primary: {
            main: '#ea1b0a',
            dark: '#ea1b0a',
            light: '#ea1b0a',
            contrastText: '#ea1b0a',
        },
        secondary: {
            main: '#fff',
        },
        turquoise: { main: '#1ea2b1' },
        grey: { [600]: '#303030' },
    },
    typography: {
        fontFamily: ['Roboto', 'Helvetica Neue', 'sans-serif'].join(','),
    },
});

theme = createTheme(theme, {
    components: {
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    info: <Info />,
                    error: <Info />,
                    success: <CheckCircleIcon />,
                },
            },
            styleOverrides: {
                root: ({ ownerState }: any) => ({
                    ...ownerState?.severity,
                    ...(ownerState?.severity === 'error' &&
                        ownerState.variant === 'standard' && {
                            border: 'solid 0.0625rem #ea1b0a',
                            backgroundColor: '#fff',
                            color: '#ea1b0a',
                            '.MuiAlert-icon': {
                                color: '#ea1b0a',
                            },
                        }),
                    ...(ownerState?.severity === 'info' &&
                        ownerState.variant === 'standard' && {
                            border: 'solid 0.0625rem #0ba2b2',
                            backgroundColor: '#fff',
                            color: '#0ba2b2',
                            '.MuiAlert-icon': {
                                color: '#0ba2b2',
                            },
                        }),
                    ...(ownerState?.severity === 'success' &&
                        ownerState.variant === 'standard' && {
                            border: 'solid 0.0625rem #00a36c',
                            backgroundColor: '#fff',
                            color: '#00a36c',
                            '.MuiAlert-icon': {
                                color: '#00a36c',
                            },
                        }),
                }),
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: { h1: 'h1' },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        height: 48,
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    margin: '-1rem -1rem 0 -1rem',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '.MuiPaper-root': {
                        padding: '1.5rem',
                        width: '75rem',
                    },
                    '.MuiDialogTitle-root': {
                        padding: 0,
                        margin: 'auto',
                        fontSize: '1.3125rem',
                        fontWeight: '500',
                    },
                    '& .MuiDialogContent-root': {
                        padding: 0,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiFormHelperText-root': {
                        height: '0',
                        marginTop: '0',
                    },
                    '& .MuiInputLabel-asterisk': {
                        color: theme.palette.error.main,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-asterisk': {
                        color: theme.palette.error.main,
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '& .MuiFormControlLabel-asterisk': {
                        color: theme.palette.error.main,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 'unset',
                    height: '0',
                    marginTop: '0',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    maxHeight: '2.3rem',
                    color: 'white',
                },
            },
        },
        MuiDateCalendar: {
            styleOverrides: {
                root: {
                    'button.Mui-selected': {
                        color: 'white',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '.MuiTabs-indicator': {
                        backgroundColor: theme.palette.turquoise.main,
                    },
                    '.MuiTab-root': {
                        textTransform: 'none',
                        '&.Mui-selected': {
                            color: theme.palette.grey[600],
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&': {
                        color: theme.palette.turquoise.main,
                    },
                    '&.Mui-checked': {
                        color: theme.palette.turquoise.main,
                    },
                },
            },
        },
    },
});

theme.typography.h1 = {
    fontSize: '2.8125rem',
    '@media (max-width:600px)': {
        fontSize: '2rem',
    },
};

theme.typography.h2 = {
    fontSize: '1.625rem',
    lineHeight: '1.25',
    fontWeight: '300',
};

theme.typography.h3 = {
    fontSize: '1.5rem',
};

theme.typography.subtitle2 = {
    ...theme.typography.subtitle2,
    fontWeight: 600,
};

export default theme;
